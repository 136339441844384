import { fetch } from '@/plugins/axios.js'
import lang from '@/utils/lang'
import site from '@/utils/site'

const apiUrl = {
  aboutUs: `/home/about_us` // 关于我们首页
}

// 关于我们首页
export const getAboutUs = () => fetch(`${apiUrl.aboutUs}/${lang}/${site}`)

