<template>
  <div class="about-us">
    <public-banner :banner-info="bannerInfo" class="about-banner-top" />
    <div v-if="aboutData" class="about-content container">
      <div class="desc" v-html="aboutData.description"></div>
      <div v-if="aboutData.product_overview && aboutData.product_overview.length > 0" class="products">
        <public-title :data-info="productTitle" />
        <swiper ref="productSwiper" class="products-list" :options="productSwiperOption">
          <swiper-slide v-for="(item, index) in aboutData.product_overview" :key="index" class="product-item">
            <div class="img-box">
              <img :src="item.image" alt="" />
            </div>
            <div class="detail">
              <div class="detail-title">{{ item.name }}</div>
              <div class="detail-desc">
                {{ item.content }}
              </div>
            </div>
          </swiper-slide>
        </swiper>
        <div class="btn-box">
          <div slot="button-prev" class="product-button-prev">
            <span class="iconfont icon-a-zuojiantou1x icon"></span>
          </div>
          <div class="product-pagination"></div>
          <div slot="button-next" class="product-button-next">
            <span class="iconfont icon-a-youjiantou1x icon"></span>
          </div>
        </div>
      </div>
      <div v-if="aboutData.honors_awards && aboutData.honors_awards.length > 0" class="honor">
        <public-title :data-info="honorTitle" />
        <swiper ref="productSwiper" class="honor-list" :options="honorSwiperOption">
          <swiper-slide v-for="(item, index) in aboutData.honors_awards" :key="index" class="honor-item">
            <div class="img-box">
              <img :src="item.image" alt="" />
            </div>
            <div class="title">{{ item.name }}</div>
          </swiper-slide>
        </swiper>
        <div v-if="honorLength > 3" slot="button-prev" class="honor-button-prev">
          <span class="iconfont icon-a-zuojiantou1x icon"></span>
        </div>
        <div v-if="honorLength > 3" slot="button-next" class="honor-button-next">
          <span class="iconfont icon-a-youjiantou1x icon"></span>
        </div>
      </div>
    </div>
    <!-- <public-banner :banner-info="footerBannerInfo" class="footer-banner about-banner-bottom" :is-custom="true">
      <template>
        <div class="footer-btn" :style="`backgroundColor: ${footerBannerInfo.button_background_color ? footerBannerInfo.button_background_color : '#fff'}`">
          <a
            href="https://www.dahuasecurity.com/aboutUs/introduction/0"
            target="blank"
            rel="nofollow noopener noreferrer"
            class="btn-link"
            :style="`color: ${footerBannerInfo.color ? footerBannerInfo.color : '#1261AD'}`"
          >
            {{ LP.lang_learn_more }}
          </a>
        </div>
      </template>
    </public-banner> -->
  </div>
</template>

<script>
import PublicBanner from '@/components/public/PublicBanner.vue'
import { mapState } from 'vuex'
import { getAboutUs } from '@/api/aboutUs'
import PublicTitle from '@/components/public/PublicTitle.vue'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.min.css'
export default {
  components: {
    PublicBanner,
    PublicTitle,
    swiper,
    swiperSlide
  },
  data() {
    return {
      bannerInfo: {},
      aboutData: {},
      productTitle: {
        title: this.LP.lang_product_overview
      },
      honorTitle: {
        title: this.LP.lang_honors_awards
      },
      productSwiperOption: {
        autoplay: false, // 自动切换
        loop: false, // 无限循环
        slidesPerView: 'auto', // 每屏显示个数
        pagination: {
          el: '.product-pagination',
          type: 'fraction'
        },
        navigation: { // 前进后退按钮
          nextEl: '.product-button-next',
          prevEl: '.product-button-prev'
        }
      },
      honorSwiperOption: {
        autoplay: false, // 自动切换
        loop: false, // 无限循环
        slidesPerView: '3', // 每屏显示个数
        centerInsufficientSlides: true, // 居中
        navigation: { // 前进后退按钮
          nextEl: '.honor-button-next',
          prevEl: '.honor-button-prev'
        },
        breakpoints: {
          1024: {
            slidesPerView: 2
          }
        }
      },
      // footerBannerInfo: {},
      honorLength: 0
    }
  },
  computed: {
    ...mapState({
      headerBanner: state => state.common.banner,
      footerBanner: state => state.common.footerBanner
    })
  },
  created() {
    this.$store.commit('header/SET_WHITE_HEADER', false)
    this.getBanner()
    this.getData()
  },
  methods: {
    // 获取banner
    async getBanner() {
      if (this.$route.fullPath.includes('business')) {
        await this.$store.dispatch('common/getBanner', { banner_id: 193 })
        this.bannerInfo = this.headerBanner[0]
        // await this.$store.dispatch('common/getFooterBanner', { banner_id: 195 })
        // this.footerBannerInfo = this.footerBanner[0]
      } else {
        await this.$store.dispatch('common/getBanner', { banner_id: 194 })
        this.bannerInfo = this.headerBanner[0]
        // await this.$store.dispatch('common/getFooterBanner', { banner_id: 196 })
        // this.footerBannerInfo = this.footerBanner[0]
      }
    },
    // 获取数据
    async getData() {
      await getAboutUs().then(({ data }) => {
        this.$store.commit('common/SET_MATE', data.seo)
        this.aboutData = data.about_us
        this.honorLength = data.about_us.honors_awards.length
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/styles/common/common.less';
.about-us {
  padding-bottom: 80px;
  .about-content {
    padding-top: 48px;
    .desc {
      font-size: 14px;
      color: #303133;
      line-height: 21px;
      /deep/ img {
        max-width: 100%;
        margin: 16px auto;
      }
      /deep/ video {
        width: 60% !important;
      }
    }
    .products {
      /deep/ .public-title {
        .text-box {
          padding: 66px 0 24px;
        }
      }
      .products-list {
        background-color: #f5f5f5;
        margin-bottom: 17px;
        border-radius: 10px;
        .product-item {
          width: 100% !important;
          display: flex;
          justify-content: space-between;
          padding: 24px 38px 24px 24px;
          .img-box {
            width: 32.5%;
            height: 264px;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
              .transition;
            }
          }
          .detail {
            width: 59%;
            .detail-title {
              margin: 10px 0;
              font-size: 18px;
              font-weight: 600;
              color: #232931;
              line-height: 18px;
            }
            .detail-desc {
              font-size: 14px;
              color: #666666;
              line-height: 21px;
            }
          }
          &:hover {
            .img-box {
              &>img {
                .scale;
              }
            }
          }
        }
      }
      .btn-box {
        display: flex;
        text-align: center;
        font-size: 18px;
        .product-pagination {
          max-width: 50px;
        }
        .product-button-prev {
          margin-left: auto ;
        }
        .product-button-next {
          margin-right: auto ;
        }
      }
    }
    .honor {
      position: relative;
      margin-bottom: 72px;
      /deep/ .public-title {
        .text-box {
          padding: 37px 0 24px;
        }
      }
      .honor-list {
        display: flex;
        justify-content: center;
        .honor-item {
          max-width: 406px;
          padding: 42px 98px;
          margin: 0 10px;
          border-radius: 10px;
          text-align: center;
          background-color: #f5f5f5;
          .img-box {
            width: 100%;
            max-width: 210px;
            overflow: hidden;
            &>img {
              width: 100%;
              .transition;
            }
          }
          .title {
            text-align: center;
            margin-top: 16px;
            font-size: 14px;
            color: #232931;
            line-height: 19px;
          }
          &:last-child {
            margin: 0;
          }
          &:hover {
            .img-box {
              &>img {
                .scale;
              }
            }
          }
        }
      }
      .honor-button-prev,
      .honor-button-next {
        position: absolute;
        top: 55%;
        z-index: 10;
        width: 48px;
        height: 48px;
        text-align: center;
        border-radius: 50%;
        .icon {
          font-size: 32px;
          color: #232931;
          line-height: 48px;
        }
      }
      .honor-button-prev {
        left: 0;
      }
      .honor-button-next {
        right: 0;
      }
    }
  }
  /deep/ .swiper-wrapper {
    height: auto;
  }
  // /deep/ .footer-banner {
  //   .banner-bg {
  //     .banner-info {
  //       .title-left-box {
  //         text-align: center;
  //       }
  //       .footer-btn {
  //         position: absolute;
  //         top: 65%;
  //         left: 50%;
  //         transform: translateX(-50%);
  //         border-radius: 4px;
  //         .btn-link {
  //           display: inline-block;
  //           padding: 10px 20px;
  //           font-size: 16px;
  //           font-weight: bold;
  //           line-height: 21px;
  //         }
  //       }
  //     }
  //   }
  // }
  @media screen and (max-width: 1024px) {
    .about-content {
      .honor {
        .honor-list {
          .honor-item {
            padding: 32px 40px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding-bottom: 40px;
    .about-banner-top {
      /deep/ .banner-bg {
        .banner-info {
          .title-left-box {
            text-align: center;
          }
        }
      }
    }
    .about-content {
      padding-top: 16px;
      .products {
        /deep/ .public-title {
          .text-box {
            padding: 24px 16px 16px 16px;
          }
        }
        .products-list {
          .product-item {
            padding: 24px;
            flex-wrap: wrap;
            .img-box {
              width: 100%;
              &>img {
                border-radius: 10px;
              }
            }
            .detail {
              width: 100%;
              .detail-title {
                margin: 18px 0 10px 0;
              }
            }
          }
        }
      }
      .honor {
        margin-bottom: 36px;
      }
      .desc {
        /deep/ video {
          width: 100% !important;
        }
      }
    }
    .about-banner-bottom {
      margin-top: 36px;
    }
  }
}
</style>
